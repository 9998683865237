.skeleton {
    width: 100%;
    padding: 0;
}

.section-title {
    margin: 20px;
}

#skeleton-scan-event {
    padding: 10px 25px;
    background-color: rgba(77, 151, 210, 0.3);
    border-radius: 5px;
    width: 90%;
    margin: 10px auto;
}

#skeleton-device-event {
    padding: 10px;
    cursor: pointer;
    margin: 10px 40px;
    border-radius: 5px;
    background: var(--mercury-color);
    width: 80%;
}