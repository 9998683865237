.status.ready {
    background-color: var(--leafy-green-color);
}

.status {
    padding: 6px 12px;
    border-radius: 25px;
    color: var(--white);
    background-color: var(--pale-red-color);
    margin: auto 0;
    font-weight: 600;
    font-size: 16px;
    width: max-content;
    text-align: center;
}

.selected-item {
    text-align: center;
}
  
.controls-container {
    --highlight-width: auto;
    --highlight-x-pos: 0;
    padding: 0;
    position: sticky;
    top: 0;
}
  
.controls {
    display: inline-flex;
    justify-content: space-between;
    background: var(--white);
    margin: auto;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid var(--light-grey-input-color);
    width: 100%;
}

.controls input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    border-radius: 10px;
}
  
.segment {
    position: relative;
    text-align: center;
    z-index: 1;
    width: -webkit-fill-available;
    border: 1px solid var(--light-grey-input-color);
  }
  
.segment label {
    cursor: pointer;
    display: block;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    transition: color 0.5s ease;
}

.segment.border-radius-left, 
.segment.active.border.border-radius-left label {
    border-top-left-radius: 8px;
}

.segment.border-radius-right, 
.segment.active.border.border-radius-right label {
    border-top-right-radius: 8px;
}
  
.segment.active {
    color: var(--black);
    background-color: var(--tropical-blue-color);
}

.segment.inactive label {
    color: var(--light-grey-input-color);
}
  
.controls::before {
    content: "";
    border-radius: 8px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    left: 0;
    z-index: 0;
}
  
/* Only allow transitions once component is ready */
.controls.ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
}

.check-icon {
    margin-right: 10px;
}

.check-icon.inactive {
    display: none;
}

.tracking-bar {
    height: 44px;
    padding: 0 20px;
}

.progress-bar {
    height: 16px;
}

.tracking-bar-col {
    margin: 0;
}

.progress-bar.active {
    background-color: var(--celestial-blue-color);
}

.progress-bar.border-left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.progress-bar.border-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.progress-bar.inactive {
    background-color: var(--light-grey-bar-color);
}

.tracking-bar p {
   text-align: center;
   font-weight: 600;
   margin: 0;
}

.battery-outside-container {
    height: 24px;
    width: 45px;
    border: 1px solid var(--ash-grey-color);
    border-radius: 4px;
}

.battery-inside-container {
    border-radius: 2.93px;
    height: 20px;
    margin: 1px;
}

.battery-inside-container.low {
    width: 8.16px;
    background: var(--valentine-red-color);
}

.battery-inside-container.medium {
    width: 32.64px;
    background: var(--orange-peel-color);
}

.battery-inside-container.high {
    width: 41px;
    background: var(--leaf-green-color);
}

.battery-right-container {
    width: 5px;
    height: 7.2px;
    background-color: var(--light-grey-input-color);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin-left: 1px;
}

.battery {
    align-items: center;
    margin-right: 8px;
}

.vl {
    border-left: 1px solid var(--celestial-blue-color);
    height: 40px;
    margin-top: 10px;
    margin-left: 11.5px;
}

.dot-outside {
    height: 24px;
    width: 24px;
    background-color: rgba(77, 151, 210, 0.3);
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.dot-inside {
    height: 14px;
    width: 14px;
    background-color: var(--celestial-blue-color);
    border-radius: 50px;
}

.marker-desc {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.no-data-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-data-container span {
    margin: 10px;
}

.option-details-info {
    display: flex;
    justify-content: space-between;
}

.option-info {
    width: -webkit-fill-available;
    margin-left: 20px;
}
.option-info.row col {
    padding: 0;
}

.option-divider {
    color: var(--storm-dust-color);
    margin: 5px 0;
    height: 10px;
}

.option-info .label {
    margin-bottom: 5px;
    font-weight: 600;
}

.option-icon {
    width: 25px;
}

.option-threshold {
    text-align: end;
    margin-bottom: 5px;
    width: max-content;
}

.threshold {
    font-weight: 700;
}

.option-col {
    display: flex;
    justify-content: end;
}

#filter-options-container p {
    color: var(--storm-dust-color);
    margin-bottom: 5px;
}

#filter-options-container svg {
    margin: 10px 10px;
}

#filter-options-container {
    height: 38px;
    margin: 5px 10px;
}