#title {
    font-weight: 600;
}

#subtitle {
    font-weight: 400;
    color: var(--pale-sky-color);
}

.dragging {
    opacity: 0.5;
}

.device-info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#view-selection {
    border-bottom: 1px solid var(--light-grey-input-color);
    border-left: 1px solid var(--light-grey-input-color);
    border-right: 1px solid var(--light-grey-input-color);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--snow-drift-color);
    bottom: 0;
    top: 0px;
}

.pill-container {
    padding: 5px 12px;
    border-radius: 20px;
}

.device-type {
    border: 1px solid var(--black);
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}

.back-button {
    cursor: pointer;
    color: var(--light-grey-input-color);
    width: fit-content;
    margin: 20px;
    font-weight: 600;
}

.back-button svg {
    margin-right: 10px;
}

#device-name {
    font-weight: 700;
    font-size: 26px;
    margin: 0 20px;
}

hr {
    color: var(--light-grey-input-border);
}

.top-container {
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
}

.top-container img {
    height: 22px;
}

.device-side-panel {
    z-index: 1;
    background: var(--white);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30%;
    overflow: auto;
    border-left: 1px solid var(--grey-goose-color);
}

.manage-device-col {
    background: var(--primary-cyan-blue-color);;
    color: var(--white);
    margin: 10px;
    text-align: end;
    cursor: pointer;
    display: flex;
    height: 40px;
    border-radius: 8px;
}

.manage-device-col span {
    padding: 7px 15px;
}

.manage-device-col span img {
    color: var(--white);
}