.device-details-label, .device-details-value {
    padding: 0;
    text-align: left;
}

.device-details-label {
    background: var(--primary-cyan-blue-color);
    color: var(--white);
    border: 1px solid var(--light-grey-input-border);
    padding: 10px;
    margin: 0;
    border-top: none;
}

.device-details-value p, .device-details-label p {
    margin: 0;
    word-break: break-word;
}

.device-details-value {
    color: var(--black);
    border: 1px solid var(--light-grey-input-border);
    padding: 10px;
    margin: 0;
    background-color: var(--white);
    font-weight: 400;
}

#device-info-table {
    margin: 0 20px 20px;
}

#info-table-border-bottom .device-details-label {
    border-bottom-left-radius: 8px;
}

#info-table-border-bottom .device-details-value {
    border-bottom-right-radius: 8px;
}

#info-table-border-top .device-details-label {
    border-top-left-radius: 8px;
}

#info-table-border-top .device-details-value {
    border-top-right-radius: 8px;
}

.top-side-panel-container {
    position: sticky;
    top: 0;
    background: var(--white);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
}

.top-side-panel-container img {
    cursor: pointer;
}

#manage-device-title {
    font-size: 20px;
    font-weight: 600;
}

.manage-device-section {
    margin-left: 20px;
    font-weight: 700;
}

.manage-device-close-btn {
    border: 1px solid var(--primary-cyan-blue-color);
    border-radius: 8px;
    color: var(--primary-cyan-blue-color);
    font-weight: 600;
    cursor: pointer;
    padding: 7px 16px;
}