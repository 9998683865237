 .preview-label {
    border-style: dashed;
    border-color: var(--grey-goose-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-label-img {
  border-style: dashed;
  border-color: var(--grey-goose-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-pdf__message.react-pdf__message--no-data {
    display: none;
}

.drag-file-container svg {
    font-size: 30px;
    padding-bottom: 10px;
}

.drag-file-container.inactive {
    display: none;
}

.file-name {
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-file-name {
  font-weight: 600;
  margin: 10px 0;
}

.file-name svg.svg-inline--fa.fa-file {
  margin-right: 10px;
  height: 24px;
}

.group-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-bottom: 20px;
}

button.change-file-button {
  border: 1px solid var(--platinum-color);
  background-color: var(--white);
  margin: 10px 0;
  color: var(--smokey-grey-color);
  height: 48px;
  align-items: center;
  font-weight: 600;
  width: -webkit-fill-available;
  text-align: center;
}

button.change-file-button:disabled {
  color: var(--smokey-grey-color);
  background-color: var(--white);
  border: 1px solid var(--platinum-color);
}

button.confirm-button {
  display: flex;
  color: var(--white);
  border: 1px solid var(--platinum-color);
  background-color: var(--primary-cyan-blue-color);
  height: 48px;
  align-items: center;
  min-width: 100px;
  justify-content: center;
  width: -webkit-fill-available;
  font-weight: 600;
}

button.confirm-button:hover {
  background-color: var(--primary-cyan-blue-color);
}

button.confirm-button.disable {
  color: var(--french-grey-color);
  background: var(--white);
  border: none;
}

button.replace-file-button.btn.btn-primary {
  margin: auto;
  border: 1px solid var(--platinum-color);
  background-color: var(--white);
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--smokey-grey-color);
  height: 48px;
  display: flex;
  align-items: center;
}

.pdf-viewer-pagination svg {
  margin: 0 5px;
  height: 20px;
}

.pdf-viewer-pagination {
  margin-top: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  color: var(--valentine-red-color);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

canvas.react-pdf__Page__canvas {
  margin: 0 auto;
  z-index: 2;
  padding: 20px;
}

.custom-pdf-page {
  width: 100% !important;
  height: auto !important;
}

.dropzone {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.dropzone:not(.empty) .preview-label {
   --scale: 0.6;
  transform: scale(var(--scale));
  transform-origin: top;
  margin-bottom: calc((var(--scale) - 1.6) * 100%);
}

.react-pdf__Page__annotations.annotationLayer, 
.react-pdf__Page__textContent.textLayer {
  display: none;
}

.drag-file-container {
    height: inherit;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.drag-file-container button.btn.btn-primary {
    background-color: var(--celestial-blue-color);
    border-radius: 5px;
    border: none;
    font-weight: 600;
    height: 48px;
}

#check-mark-icon {
    height: 24px;
    width: 24px;
    border-radius: 20px;
    background-color: var(--leaf-green-color);
    margin-left: 10px;
    color: var(--white);
    padding: 1px;
    position: relative;
    bottom: -5px;
}

.confirm-button .sk-chase .sk-chase-dot:before{
    background-color: var(--white);
}

.label-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}

.label-desc {
  color: var(--white);
  margin: auto;
}

#label-file {
  color: var(--pale-sky-color);
  font-weight: 400;
  font-size: 14px;
  margin: 15px 0 5px 0;
}

.pin-container p {
  margin: 0;
  font-size: 15px;
}

.pin-container {
  display: block;
  align-items: center;
  justify-items: center;
  float: left;
}

.pin-container p:not(#label-file):after {
  color: var(--valentine-red-color);
  content: ' *';
  display: inline;
}

.label-upload-btn {
  background: var(--celestial-blue-color);
  color: var(--white);
  border-radius: 5px;
  padding: 10px 0px;
  cursor: pointer;
  font-weight: 600;
  width: -webkit-fill-available;
  text-align: center;
}

.label-upload-btn svg {
  margin-right: 10px;
}


#label-pin-col {
  display: flex;
  align-items: center;
  padding: 30px;
  margin: 30px 0;
  border-right: 1px solid var(--grey-goose-color);
}

#label-format {
  font-weight: 400;
  color: var(--pale-sky-color);
}

.label-pin {
  font-size: 14px;
  font-weight: 400;
}

#pending-label-eta {
  background: var(--leaf-green-color);
  position: absolute;
  margin: 0;
  min-width: 300px;
  width: -webkit-fill-available;
  text-align: center;
  color: var(--white);
  padding: 10px 0;
  z-index: 999;
}

#pending-label-eta-image {
  background: var(--leaf-green-color);
  position: absolute;
  margin: 0;
  width: -webkit-fill-available;
  text-align: center;
  color: var(--white);
  padding: 10px 0;
  z-index: 999;
}

#pending-label-eta #title {
  font-weight: 700;
}

#pending-label-eta #subtitle {
  font-weight: 400;
  font-size: 14px;
  color: var(--white);
}

#pending-label-eta-image #title {
  font-weight: 700;
}

#pending-label-eta-image #subtitle {
  font-weight: 400;
  font-size: 14px;
  color: var(--white);
}

.empty-label-container {
  border-style: dashed;
  border-color: var(--grey-goose-color);
  border-radius: 8px;
  background-color: var(--black);
  height: 100%;
  display: flex;
  position: absolute;
  opacity: 0.7;
  border-radius: 8px;
  width: 175px;
}

.empty-label-container.pending {
  z-index: 99;
  height: -webkit-fill-available;
  width: 300px;
}

#empty-label-img {
  padding: 32px 20px;
  max-width: 175px;
}

.upload-label-container{
  border-radius: 6px;
  margin: 20px 20px 40px;;
  padding: 10px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid var(--grey-goose-color);
}

.current-label-container,
.pending-label-container {
  max-width: 175px;
  margin: 0 10px;
}

.label-viewer {
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}