.google-maps-inactive {
    background-image: url("../assets/google_maps.png");
    height: 60vh;
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.info-window p {
    font-family: 'Open Sans';
}

.info-window p#datetime {
    font-weight: 400;
    font-size: 13px;
    color: var(--cool-grey-color);
    margin-bottom: 5px;
}

.info-window p#status {
    font-weight: 700;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 3px;
}

.info-window p.description {
    font-weight: 400;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 0px;
}

.gm-style-iw-chr{
    height: 30px !important;
}

.custom-marker-cluster div {
    font-family: 'Open Sans' !important;
    color: var(--white) !important;
    font-size: 15px !important;
}