.devices-container {
    overflow: auto;
    margin: 0 20px;
}

.device-table {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid var(--light-grey-border-color);
    width: 100%;
}

.header {
    background-color: var(--primary-cyan-blue-color);
    color: var(--white);
    height: 54px;
}

tr.header th {
    padding-left: 20px;
    width: 20%;
    text-align: left;
    font-weight: 600;
}

th#name {
    border-top-left-radius: 8px;
}

th#timestamp {
    border-top-right-radius: 8px;
    cursor: pointer;
}

tbody tr td {
    border-bottom: 1px solid var(--light-grey-border-color);
    padding: 10px 20px;
}

.device-details div {
    width: max-content;
}

.device-details p {
    margin: 0;
}

.search-txt input {
    border: none;
    font-family: 'Open Sans';
    margin-left: 10px;
}

.search-txt input:focus {
    outline: none;
}

.search-txt {
    border: 1px solid var(--light-grey-input-border);
    color: var(--light-grey-input-color);
    padding: 0 8px;
    border-radius: 8px;
    display: flex;
    float: right;
    align-items: center;
    justify-items: center;
    height: 38px;
}

.sorting-icon {
    margin: auto 10px;
}


#page-title {
    margin: 20px 30px;
    font-size: 25px;
    font-weight: 700;
}

.clear-icon.hide {
    display: none;
}

.clear-icon {
    display: block;
}

th#name,
th#type,
th#inFlight {
    width: 20%;
}

th#timestamp {
    width: 40%;
}

.filter {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin-bottom: 30px;
    margin-left: 20px;
}

.dropdown-button button {
    background-color: var(--white) !important;
    border: 1px solid var(--light-grey-input-border) !important;
    color: var(--cool-grey-color) !important
}

.dropdown-title {
    color: var(--black);
    font-weight: 600;
    margin-left: 7px;
}

.item-label label {
    float: left;
    color: var(--black) !important;
    text-align: left;
    width: max-content;
}

.item-label input[type=checkbox] {
    border-radius: 2px !important;
}

.item-label input {
    background-color: var(--ash-grey-color) !important;
    border-color: var(--ash-grey-color) !important;
}

.item-label input:not(:checked) {
    background-color: var(--white) !important;
}

.dropdown-menu.show {
    box-shadow: 1px 1px 9px 0px #00000040 !important;
}

.device-dropdown-menu {
    width: 14em;
}

.item-label {
    margin: 20px;
    text-transform: capitalize;
}

.endDate {
    width: 100px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid var(--light-grey-input-border);
    margin: 0 10px;
    font-weight: 600;
}

.radio-menu {
    width: 320px
}

.custom-range-dropdown {
    margin-top: 15px;
    margin-left: 20px;
}

.custom-range-dropdown:active,
.custom-range-dropdown:checked {
    background-color: transparent;
}

.device-table td,
.device-table th {
    text-wrap: nowrap;
    cursor: pointer;
}

.header th {
    position: sticky;
    top: -1px;
    background-color: var(--primary-cyan-blue-color);
}

.empty-row td {
    text-align: center;
}

.header col div svg[data-icon*='sort-up']~svg {
    display: none;
}

@media screen and (max-width: 767px) {
    #page-title {
        margin: 0 20px 10px;
    }
}

.name-cell {
    color: var(--leafy-green-color);
    cursor: pointer;
}

.name-cell:hover {
    color: var(--leafy-green-color);
}