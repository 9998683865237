.nav {
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    color: var(--black);
}

.nav-option {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 10px;
}

.nav-option.option {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
}

.nav-option.option p {
    margin: 10px;
}

.nav-option.option.selected {
    background-color: var(--nile-blue-color);
    border-radius: 8px;
}

.sidebar-container {
    padding: 10px;
    color: var(--white);
    /** 60 is a default height of nav tag */
    /** 10 is margin top */
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--primary-cyan-blue-color);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.sidebar-container.isCollapsed .nav-option.option {
    padding: 12px 16px;
}
.sidebar-container:not(.isCollapsed) .nav-option.option {
    min-width: 210px;
}
.sidebar-container.qa-env {
    background-image: url('../assets/vistaqa.png');
}
.sidebar-container.dev-env {
    background-image: url('../assets/vistadev.png');
}

.nav-option:hover:not(.navCollapse), .nav-option.option:hover:not(.navCollapse) {
    color: var(--white);
    background-color: var(--nile-blue-color);
    cursor: pointer;
    border-radius: 8px;
    margin: 10px;
}

.sidebar-container::-webkit-scrollbar {
    display: none;
}

.logo {
    display: flex;
    color: var(--white);
    gap: 20px;
    align-items: center;
    margin: auto;
}

.logo img {
    max-width: 126.56px;
    margin: auto;
    max-height: 40px;
}

.main-container {
    display: flex;
    overflow: hidden;
}

.sidebar-content {
    z-index: 999999;
    width: 88px;
    background-color: var(--primary-cyan-blue-color);
    transition: all .2s;
}

.sidebar-content:hover {
    width: 250px;
} 

.avatar {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

.avatar-container {
    color: var(--white);
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    
}
.avatar-container.isCollapsed {
    bottom: 12px;
    left: 25px;
}

#avatar-logo {
    background-color: var(--tropical-blue-color);
    color: var(--dark-midnight-blue-color);
    font-weight: 600;
    font-size: 13px;
    border-radius: 20px;
}

.user-name {
    word-break: break-word;
    text-align: left;
    white-space: normal;
    width: 120px;
}

.avatar-container svg {
    font-size: 30px;
}

.avatar-container p {
    margin: auto;
}

.username {
    font-weight: bold;
    text-align: center;
    color: var(--light-blue-ava-color);
}

.avatar-tooltip p {
    color: var(--black);
}

.logout-btn {
    padding: 5px 30px;
    border: none;
    background-color: var(--white);
    color: var(--black);
}

.footer {
    width: fit-content;
    float: right;
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.footer p {
    color: var(--cool-grey-color);
    font-size: 12px;
    float: right;
    margin: 0;
    padding: 0px;
}

.footer p img {
    width: 93px;
    position: relative;
    bottom: 5px;
}

.page-content {
   height: 100vh;
}

.notification-icon {
    font-size: 20px;
}

.alert-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--red);
    position: absolute;
}

.notification-container {
    display: block;
}

.device-icon {
    transform: rotate(-90deg);
    width: 18px;
}

@media screen and (min-width: 768px) {
    .sidebar-container.navCollapse .nav-option:hover, .nav-option.option:hover {
        cursor: pointer;
    }
    .navCollapse {
        width: 80px;
    }
    .navCollapse .nav-option p {
        display: none;
    }
    .navCollapse .nav-option.option {
        padding: 20px;
    }
    .small-device-icon {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .sidebar-content:hover {
        width: fit-content;
    }

    .nav {
        margin-top: 0;
        height: 50px;
    }
    .main-container {
        display: block;
    }

    .sidebar-content {
       height: 50px;
       background: var(--white);
       width: 50px;
    } 

    .logo {
        color: var(--black);
    }
    .nav.isCollapsed {
        background-color: var(--white);
    }

    .nav.isCollapsed .logo{
        color: var(--black);
    }

    .sidebar-container:not(.smallNav) {
        width: 54px;
        padding-left: 0px;
        display: none;
    }

    .sidebar-container:not(.smallNav) .nav-option {
        padding: 20px;
    }

    .larger-device-icon {
        display: none;
    }

    .smallNav {
        display: block;
    }

    .sidebar-container:not(.smallNav) .nav-option p{
        display: none;
    }

    .sidebar-container.smallNav {
        position: absolute;
        border-top-right-radius: 10px;
        z-index: 99999;
    }

    .sidebar-container {
        height: calc(100vh - 50px);
    }

    .logo img {
        display: none;
    }

    .back-button, .manage-device-col {
        margin: 10px !important;
    }
}