.login-container {
    background-image: url("../assets/login_background.jpg");
    width: 100vw;
    height: 100vh;
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-main-container {
    padding: 20px;
    width: 363px;
    height: 374px;
    background: var(--white);
    border-radius: 8px;
    align-items: center; 
    justify-content: center;
    overflow: hidden;
    text-align: center;
}

#img-container {
    justify-content: center;
    margin: 30px;
}

#img-container img {
    width: 243px;
}

#microsoft-icon img{
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

#microsoft-icon {
    display: contents;
    border: 1px solid;
}

#login-btn-container {
    margin-top: 40px;
    border: 1px solid var(--oslo-grey-color);
    width: max-content;
    padding: 10px 15px;
    align-items: center;
    text-align: center;
    margin: auto;
    color: var(--concord-color);
    cursor: pointer;
    display: inline-flex;
}

#login-btn-container p{
    font-weight: 700;
    margin: 0;
}

#login-footer-container {
    margin-top: 80px;
}

#login-footer-container p {
    color: var(--cool-grey-color);
    font-size: 13px;
    font-weight: 700;
}

#login-footer-container p img {
    width: 93.44px;
}