.tracking-details .col {
    width: fit-content;
}

.tracking-details p {
    width: fit-content;
    margin-bottom: 5px;
}

#tracking-desc {
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
}

#tracking-datetime {
    width: -webkit-fill-available;
    color: var(--pale-sky-color);
    font-size: 15px;
    padding: 5px 32px;
    font-weight: 400;
}

#tracking-location, #delivery-address {
    font-weight: 700;
    font-size: 16px;
    color: var(--black);
}
.scroll-up-button {
    cursor: pointer;
    position: sticky;
    bottom: 10px;
    float: right;
    font-size: 25px;
    color: var(--light-grey-input-color);
    right: 10px;
}

.dest-address-container.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    border-top: 0.5px solid var(--grey-goose-color);
    background: var(--white);
}

.dest-address-container.unset {
    position: unset;
}

#destination-label {
    margin: 10px 0 0 0;
    color: var(--pale-sky-color);
    font-size: 15px;
    padding: 0 20px;
    font-weight: 400;
}

#delivery-address {
    padding: 10px 20px 0;
}

.tracking-details.row {
    margin-bottom: 10px;
}

p.event-datetime {
    background-color: var(--tropical-blue-color);
    border-radius: 20px;
    padding: 2px 7px;
    font-size: 14px;
    cursor: pointer;
}

.event-details {
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 20px;
    border-left: 1px dashed;
    cursor: pointer;
}

.event-info {
    font-size: 15px;
}
.event-details .title {
    font-weight: 600;
}

.google-maps-col {
    padding: 0;
}

.tracking-scan-event {
    padding: 16px;
    background-color: var(--seashell-color);
    border-radius: 6px;
    width: 90%;
    margin: 10px auto;
    position: relative;
}

.tracking-scan-event.not-empty {
    margin-bottom: 10px;
}

#device-event {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    background: var(--mercury-color);
    width: 90%;
    margin: 5px auto;
}

.device-event-desc {
    font-size: 14px;
}

#tracking-event-datetime {
    font-size: 14px;
    font-weight: 700;
    color: var(--cool-grey-color);
    margin: 5px 0;
}

.input-clear-icon.hide {
    display: none;
}

.input-clear-icon {
    display: block;
}

#empty-tracking-info {
    margin-left: 20px;
}

#empty-tracking-info svg {
    margin-right: 10px;
}

.pin-dropdown-button button {
    background-color: var(--white) !important;
    border: 1px solid var(--light-grey-input-border) !important;
    color: var(--cool-grey-color) !important;
}

.filters-dropdown-button button {
    background-color: var(--white) !important;
    border: none;
    color: var(--black) !important;
    font-weight: 600;
    width: 150px;
    display: flex;
}

.filters-toggle::after {
    position: absolute;
    right: 0;
    top: 15px;
    bottom: 0;
}

#pin-dropdown-title {
    margin-left: 10px;
    color: var(--black);
    font-weight: 600;
}

.maps-filter-checkbox {
    position: absolute;
    right: 5px;
}

div#pin-dropdown-menu {
    padding: 0;
    z-index: 9999;
    width: max-content;
}

.pin-dropdown-item.item-border-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.pin-dropdown-item.item-border-bottom {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pin-dropdown-item:active {
    background-color: var(--light-grey-input-border);
    color: var(--black);
}

.pin-dropdown-item.dropdown-item p{
    margin: 0;
    text-align: center;
}

.pin-dropdown-item {
    padding: 10px !important;
}

#event-marker-cont {
    display: flex;
    position: relative;
}

.marker-icon {
    position: absolute;
    right: 0;
}

.event-date-picker {
    background-color: var(--white);
    height: fit-content;
    padding: 0 10px;
    border-radius: 8px;
    cursor: pointer;
}

.tracking-container {
    /* 105px: height of top control */
    /* 140px: margin bottom to google maps button controls */
    height: calc(100vh - 105px - 140px);
    overflow: auto;
    background: var(--white);
    z-index: 2;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tracking-info-panel {
    display: flex;
    flex-direction: column;
    background: var(--white);
    border-radius: 8px;
    max-width: 28vw;
    z-index: 2;
}

.tracking-info-panel,
.pin-dropdown-button button,
.event-date-picker,
.filters-dropdown-container,
.show-path {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.filters-dropdown-button button:focus {
    outline: none;
}

.tracking-maps-control .control {
    height: fit-content;
    z-index: 2;
}

.collapsed-panel {
    cursor: pointer;
    font-weight: 600;
    padding: 7px;
}

.collapsed-panel span {
    padding: 0 10px;
}

.rmdp-input:focus {
    border: none;
    box-shadow: none;
}

.end-date-picker {
    font-weight: 600;
    padding: 0 10px
}

.custom-event-option {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.custom-event-option p {
    padding: 0 10px;
    width: max-content;
}

.time-selection {
    font-weight: 400;
    text-align: left;
    padding: 10px
}

.tracking-maps-control {
    position: absolute;
    display: flex;
    margin-top: 105px;
    width: -webkit-fill-available;
}

.filters-dropdown-footer {
    display: flex;
    padding: 7px 10px 0;
    justify-content: space-between;
    border-top: 0.5px solid var(--grey-goose-color);
}

.filters-select-all, .filters-clear {
    cursor: pointer;
}

.filters-select-all.disabled, .filters-clear.disabled {
    color: var(--light-grey-input-color);
}

.filters-count {
    font-weight: 400;
    color: var(--light-grey-input-color);
    padding: 0;
    display: inline;
    position: absolute;
    right: 15px;
}
.filters-custom-checkbox input[type='checkbox']:not(:checked) {
    border: 1px solid var(--primary-cyan-blue-color);
}

.filters-custom-checkbox input[type='checkbox']:checked {
    background-color: var(--primary-cyan-blue-color);
    border: 1px solid var(--primary-cyan-blue-color);
    color: var(--white);
}

.filters-menu-icon {
    width: 24px;
    text-align: center;
}

.datetime-input-picker:not(.collapsed) {
    width: 150px;
}

.datetime-input-picker.collapsed {
    width: 105px;
}

.datetime-input-picker:focus {
    border: none;
    box-shadow: none;
}

.time-selection .isCollapsed {
    height: 50px;
}

.show-path {
    display: flex;
    height: 38px;
    background: var(--white);
    border-radius: 8px;
    padding: 7px;
}

.show-path span {
    padding: 0 5px;
    font-weight: 600;
}

.rmdp-range ,.rmdp-panel-body li, .rmdp-panel-body li.bg-blue {
    background-color: var(--primary-cyan-blue-color) !important;
}

.rmdp-week-day, .time-selection, .rmdp-arrow {
    color: var(--celestial-blue-color) !important;
}

.rmdp-day.rmdp-today span {
    background: var(--celestial-blue-color) !important;
}

.rmdp-container input:focus{
    outline: none !important;
}

.rmdp-container input {
    border-radius: 8px;
    border: none;
    height: 38px;
    margin: 0 5px;
    font-weight: 600;
    caret-color: transparent;
}

.reset-button {
    display: flex;
    padding: 5px;
    cursor: pointer;
}

.reset-button span {
    font-weight: 600;
    padding: 3px 10px;
}

.reset-button span svg {
    padding: 0 5px
}

.filters-dropdown-container {
    background: var(--white);
    border: 1px solid var(--light-grey-input-border) !important;
    border-radius: 8px;
    height: 38px;
    width: 195px;
}

@media screen and (max-width: 767px) {
    .tracking-maps-control {
        flex-direction: column-reverse;
    }
    
    .tracking-info-panel {
        max-width: 85vw;
        max-height: 53vh;
    }
    .google-maps-container {
        /* 50px is the height of hamburger side menu */
        height: calc(100vh - 50px) !important;
    }
}

@media screen and (max-width: 1025px) and (min-width: 768px) {
    .device-side-panel {
        width: 40vw !important;
    }
    
    .tracking-info-panel {
        width: inherit;
    }
}