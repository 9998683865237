@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans';
}

.fs-7 {
    font-size: 0.875em;
}

// Importing sections of bootstrap (importing just "bootstrap" will break npm run build)

@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #001996;

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../../node_modules/bootstrap/scss/buttons";

.container {
    margin: 0px;
    padding: 0px;
}

.main-content {
    position: absolute;
     /** 88px is default width of sidemenu when collapsed */
    left: 88px;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

table {
    border-spacing: 0px;
}

.Toastify__toast-theme--dark {
    background: var(--nile-blue-color) !important;
}

.Toastify__toast-container.Toastify__toast-container--top-right.label-toast-container {
    width: auto;
}

.Toastify__toast-body {
    font-family: "Open Sans";
}

@media screen and (max-width: 767px) {
    .main-content {
        padding: 0;
        width: 100vw;
        left: 0;
        /* 50px is the height of hamburger side menu */
        top: 50px;
    }

    .device-side-panel {
        width: 90%;
    }

    .page-content {
        margin: 0;
        /* 50px is the height of hamburger side menu */
        max-height: calc(100vh - 50px);
    }
}

:root {
    --red: #FF0000;
    --pale-red-color: #DA5050;
    --white: #FFF;
    --black: #000000;
    --celestial-blue-color: #4D97D2;
    --primary-blue-color: #001996;
    --primary-cyan-blue-color: #102B41;
    --nile-blue-color: #163B5A;
    --tropical-blue-color: #C9DCED;
    --dark-midnight-blue-color: #003769;
    --mariner-color: #2B75D2;
    --grey-background-color: #CFEAD6;
    --light-grey-bar-color: #D9D9D9;
    --light-grey-color: #EAEAEA;
    --light-grey-background-color: #F5F5F5;
    --light-grey-border-color: #F0F0F0;
    --light-grey-input-border: #E7E8E9;
    --mercury-color: #E5E5E5;
    --snow-drift-color: #F8F9FA;
    --light-grey-input-color: #888C90;
    --french-grey-color: #BEBEBE;
    --concord-color: #7B7B7B;
    --oslo-grey-color: #8C8C8C;
    --cool-grey-color: #9FA3A6;
    --seashell-color: #F1F1F1;
    --grey-goose-color: #CDD2D8;
    --ash-grey-color: #B7BABC;
    --light-blue-color: #F5F6FB;
    --blue-ava-color: #0087A5;
    --light-blue-ava-color: #B3E5F0;
    --leafy-green-color: #57B52A;
    --leaf-green-color: #61A30A;
    --pale-sky-color: #707479;
    --grey-goose-color: #CFD1D2;
    --tropical-blue-color: #CAE0F2;
    --orange-peel-color: #FFA300;
    --valentine-red-color: #EB5757;
    --platinum-color: #E4E4E4;
    --smokey-grey-color: #707070;
    --storm-dust-color: #656667
}

/* Throw-away */
@import "~react-image-gallery/styles/scss/image-gallery.scss";