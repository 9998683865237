.toggle-slider {
    position: relative;
    width: 50px;
    height: 25px;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: var(--white);
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .toggle-slider input[type='checkbox'] {
    display: none;
  }
  
  .toggle-slider input[type='checkbox']:checked + .slider {
    background-color: var(--primary-cyan-blue-color);
  }
  
  .toggle-slider input[type='checkbox']:checked + .slider:before {
    transform: translateX(20px);
  }